import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import MainBtn from "../components/button/mainBtn"

const Subscribe = () => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(email, {
      FULLNAME: name,
    })
      .then(data => {
        alert(data.msg)
      })
      .catch(error => {
        alert("Check email address or name")
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  const handleNameChange = event => {
    setName(event.currentTarget.value)
  }

  return (
    <form onSubmit={handleSubmit} className="subscribe">
      <h3 className="page-title">
        Sign up and be the first to know when Servbees is up!
      </h3>
      <p>
        Get access to exclusive content and promos, plus the best deals around
        your area.
      </p>
      <div className="subscribe-form">
        <input
          placeholder="Email"
          name="email"
          type="email"
          className="subscribe-input"
          required
          onChange={handleEmailChange}
        />
        <input
          placeholder="Full Name"
          name="name"
          type="text"
          className="subscribe-input"
          onChange={handleNameChange}
        />
        <MainBtn text="Subscribe" type="submit" />
      </div>
    </form>
  )
}

export default Subscribe
