import React from "react"
import Layout from "../components/layout"
import Subscribe from "../components/subscribe"

const index = () => {
  return (
    <Layout className="sign-up">
      <div className="container">
        <Subscribe />
      </div>
    </Layout>
  )
}

export default index
